<template>
  <div class="wap-product-detail">
    <headerBar :title="$t('cai-gou-que-ren')" @back="back"></headerBar>

    <div
      class="detail-box"
      v-for="(good, i) in detailData.OrderProduct"
      :key="i"
    >
      <div class="title flex-center-between">
        <div class="label">{{ $t('chan-pin-bian-hao') }}</div>
        <div class="value flex-center">
          {{ good.BusinessProduct.ID }}<i class="el-icon-copy-document"></i>
        </div>
      </div>
      <div class="top-info flex">
        <div class="img">
          <img :src="good.BusinessProduct.MainImage" alt="" />
        </div>
        <div class="flex-1 info">
          <div class="title">
            {{
              good.BusinessProduct.ProductTranslation
                ? good.BusinessProduct.ProductTranslation[0].Name
                : ''
            }}
          </div>
        </div>
      </div>

      <div class="flex-center-between line">
        <div class="label">{{ $t('xiao-shou-jia') }}</div>
        <div class="value blue">${{ good.Price }}</div>
      </div>
      <div class="flex-center-between line">
        <div class="label">{{ $t('cai-gou-jia') }}</div>
        <div class="value blue">${{ good.PickupAmount }}</div>
      </div>
      <div class="flex-center-between line">
        <div class="label">{{ $t('li-run-0') }}</div>
        <div class="value blue">${{ good.Income }}</div>
      </div>
      <div class="flex-center-between line">
        <div class="label">{{ $t('shu-liang') }}</div>
        <div class="value blue">{{ good.Number }}</div>
      </div>
    </div>

    <div class="detail-box">
      <div class="flex-center-between line">
        <div class="label">{{ $t('mai-jia-fu-kuan') }}</div>
        <div class="value">${{ detailData.TotalAmount }}</div>
      </div>
      <div class="flex-center-between line">
        <div class="label">{{ $t('cai-gou-jin-e') }}</div>
        <div class="value">
          ${{ detailData.TotalAmount - detailData.Income }}
        </div>
      </div>
      <div class="flex-center-between line">
        <div class="label">{{ $t('li-run-0') }}</div>
        <div class="value">${{ detailData.Income }}</div>
      </div>
      <div class="flex-center-between line">
        <div class="label">{{ $t('shui') }}</div>
        <div class="value">$0.00</div>
      </div>
      <div class="flex-center-between line">
        <div class="label">{{ $t('yun-fei') }}</div>
        <div class="value">$0.00</div>
      </div>
      <div class="flex-center-between line">
        <div class="label">{{ $t('he-ji') }}</div>
        <div class="value blue">
          ${{ (detailData.TotalAmount - detailData.Income).toFixed(2) }}
        </div>
      </div>
    </div>

    <Button type="info" class="order-btn" @click="toPay">
      {{ $t('li-ji-zhi-fu') }}
      ${{ (detailData.TotalAmount - detailData.Income).toFixed(2) }}
    </Button>

    <Popup v-model="showPopup" @cancel="cancel" position="bottom" round>
      <div class="password-popup">
        <div class="title">
          {{ $t('qing-shu-ru-jiao-yi-mi-ma') }}
          <i @click="cancel" class="el-icon-close"></i>
        </div>
        <PasswordInput :focused="showPopup" :value="password"></PasswordInput>
        <NumberKeyboard
          :show="showPopup"
          v-model="password"
          @input="doPay"
          @hide="cancel"
          :maxlength="6"
        ></NumberKeyboard>
      </div>
    </Popup>
  </div>
</template>
<script>
import {
  Button,
  DatetimePicker,
  Popup,
  PasswordInput,
  NumberKeyboard
} from 'vant'
import { pickupPay } from '@/api/shop'
import headerBar from '@/components/header'
import moment from 'moment'
export default {
  components: {
    Button,
    headerBar,
    DatetimePicker,
    PasswordInput,
    Popup,
    NumberKeyboard
  },
  data() {
    return {
      carUrl: require('@/assets/imgs/icon-car.png'),
      messageUrl: require('@/assets/imgs/icon-message.png'),
      dataId: '',
      detailData: {
        ShippingAddress: {},
        OrderProduct: []
      },
      logistics: {},
      logisticsList: [],
      showPopup: false,
      password: ''
    }
  },
  computed: {
    langList() {
      return this.$store.state.langList
    },
    lang() {
      return this.$store.state.lang
    }
  },
  mounted() {
    this.dataId = this.$route.query.id
    this.init()
  },
  methods: {
    back() {
      this.$router.go(-1)
    },
    init() {
      let data = sessionStorage.getItem('orderDetail')
      if (data) {
        this.detailData = JSON.parse(data)
        console.log(111, this.detailData)
      }
    },
    getStatusName(status) {
      let name = ''
      switch (status + '') {
        case '0':
          name = this.$t('dai-fu-kuan')
          break
        case '1':
          name = this.$t('dai-fa-huo')
          break
        case '2':
          name = this.$t('dai-shou-huo')
          break
        case '3':
          name = this.$t('dai-ping-jia')
          break
        case '4':
          name = this.$t('yi-wan-cheng')
          break
        case '5':
          name = this.$t('yi-qu-xiao')
          break
        case '6':
          name = this.$t('yi-guan-bi')
          break
        case '7':
          name = this.$t('tui-huo-zhong')
          break
        case '8':
          name = this.$t('tui-kuan-cheng-gong')
          break
        case '9':
          name = this.$t('yi-ping-jia')
          break
      }
      return name
    },
    toPath(path) {
      this.$router.push({
        name: path
      })
    },
    toPay() {
      this.showPopup = true
    },
    doPay() {
      setTimeout(() => {
        if (this.password.length == 6) {
          pickupPay({
            ID: parseFloat(this.detailData.ID),
            Password: parseFloat(this.password)
          }).then((res) => {
            if (res.code == 0) {
              this.showPopup = false
              this.$toast(this.$t('cao-zuo-cheng-gong'))
            } else {
              this.$toast(res.msg)
            }
          })
        }
      }, 300)
    },
    cancel() {
      this.showPopup = false
    }
  }
}
</script>